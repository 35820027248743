<template>
  <UiPopup
    :model-value="modelValue"
    title="Unsuccessful contact"
    :primary-button-text="checking ? '' : 'Close lead'"
    :secondary-button-text="checking ? '' : 'Keep working'"
    size="extraSmall"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @cancel="emits('cancel')"
    @confirm="canClose ? submit() : (canClose = true)"
  >
    <div class="transition-all duration-200">
      <UiLoader v-if="checking" />
      <form v-else-if="canClose" ref="form" @submit.prevent>
        <div class="mb-6 flex flex-col gap-4 rounded-2xl bg-primary-05 px-2 pb-6 pt-4">
          <span class="leading-6"
            >You made <span class="font-medium">{{ lead?.missed_calls_count }} unsuccessful attempts</span> to call this
            lead. Close as Lost right now or keep working later. Please indicate a reason why this lead was lost.
          </span>
        </div>
        <div>
          <UiInputSelect
            v-model="data.pipeline_step_id"
            :items="reasonsItems"
            name="Reason"
            placeholder="Choose a reason"
            class="mb-4"
            :error="useGetFieldErrors(v$, ['pipeline_step_id'])"
          />
          <UiInputTextField
            v-model="data.comment"
            :disabled="true"
            name="Other"
            placeholder="Or add other reason"
            class="mb-4"
            :error="useGetFieldErrors(v$, ['comment'])"
          />
        </div>
      </form>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useUiStore } from '@/store/ui'
import type { Lead, Stage } from '@/types'
import { PIPELINES, STAGES } from '@/constants'

const emits = defineEmits(['update:modelValue', 'input', 'cancel'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const uiStore = useUiStore()

const stage = ref<Stage>()
const reasonsItems = ref([])

const checking = ref(false)
const loading = ref(false)
const canClose = ref(true)

onNuxtReady(async () => {
  checking.value = true
  const salesPipeline = await useGetPipelineByCode(PIPELINES.SALES)
  stage.value = salesPipeline.stages.find((s) => s.code === STAGES.CLOSED)
  reasonsItems.value = stage.value.steps
    ?.filter((s) => s.name !== 'Won' && s.name !== 'Other')
    .map((r) => ({ value: r.id, text: r.name }))
  canClose.value = reasonsItems.value?.length
  checking.value = false
})

const data = ref({
  pipeline_step_id: null,
  comment: '',
})
const rules = computed(() => ({
  pipeline_step_id: { required: helpers.withMessage('The reason is required', required) },
  comment: { required: helpers.withMessage('The comment is required', required) },
}))
const v$ = useVuelidate(rules, data)

watch(
  () => props.lead.missed_calls_count,
  () => {
    data.value.comment = `${props.lead.missed_calls_count} Unsuccessful calls`
  },
  { immediate: true }
)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  loading.value = true
  const cleanData = omitBy(toRaw(data.value), (f) => f === '' || f === null)
  const payload = {
    pipeline_stage_id: stage?.value.id,
    pipeline_step_id: cleanData.pipeline_step_id,
    ...cleanData,
  }
  try {
    const response = await useCloseLead(props.lead.id, payload)

    uiStore.showSnackBanner(response.message)
    emits('input', response.data)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
